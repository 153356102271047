import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import Typography from "@material-ui/core/Typography";
import AddNews from "../AddNews";
import Draft from "../Draft";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
function ManageNews(props) {
  const classes = useStyles();

  const [tags, setTags] = useState(null);
  const [drafts, setDrafts] = useState([]);
  const [error, setError] = useState("");
  //const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const msg = "Non si è autorizzati ad accedere alla risorsa. ";

  useEffect(() => {
    const getTags = async () => {
      const resp = await props.firebase.getTags(props.role, props.token);
      if (resp.isOk) {
        const t = resp.tags.data.tags.map(function (tag) {
          return { id: tag.id, title: tag.title };
        });
        //console.log(t);
        setTags([...t]);
        //setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getTags();
  }, [props.firebase, props.token, props.role, error]);
  useEffect(() => {
    const getDrafts = async () => {
      const resp = await props.firebase.getDrafts(props.role, props.token);
      if (resp.isOk) {
        const d = resp.drafts.data.drafts;
        setDrafts([...d]);
        //console.log(d);
        //setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getDrafts();
  }, [props.firebase, props.token, props.role, error]);
  const addnewDraft = (newItem) => {
    setDrafts([...drafts, newItem]);
    console.log(drafts);
  };
  const updateDraftList = (id) => {
    let NewList = [...drafts];
    for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].id === id) {
        NewList.splice(i, 1);
      }
    }

    setDrafts(NewList);
  };
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper}>
                <Title>Aggiungi News</Title>
                <CssBaseline />
                {tags ? (
                  <AddNews tags={tags} callBack={addnewDraft} />
                ) : (
                  <LinearProgress />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Bozze salvate</Title>

                {drafts && tags ? (
                  drafts.length !== 0 ? (
                    <Grid container spacing={2}>
                      {drafts.map((draft) => (
                        <Draft
                          tags={tags}
                          key={draft.id}
                          draft={draft}
                          callBack={updateDraftList}
                        ></Draft>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      <em>Non ci sono bozze</em>
                    </Typography>
                  )
                ) : (
                  <LinearProgress />
                )}
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                }}
                title={"Esito operazione"}
                maxWidth="xs"
              >
                <Typography component={"span"} variant={"body2"}>
                  {msg}
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}
const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(ManageNews);
