// Material UI components
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withFirebase } from "../../services/";
import { Formik } from "formik";
import * as Yup from "yup";
import { LANDING } from "./../../const/extRoutes";
import { MyDialog } from "../MyDialog";
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progess: {
    position: "absolute",
  },
});
const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Inserisci una mail valida")
    .required("Campo obbligatorio")
    .trim()
    .matches(
      /^.*(@scuola.alto-adige.it|@provincia.bz.it)$/,
      "Usa la mail istituzionale"
    ),
});
const Recovery = (props) => {
  const { classes } = props;
  const [status, setStatus] = useState([]);
  const handleDialogClose = () => {
    setStatus({ isOpen: false, err: "" });
    if (status.regOK) {
      setStatus({ regOK: false, isOpen: false });
      props.history.push(LANDING);
    }
  };
  const onResetSuccess = () => {
    setStatus({
      regOK:
        "E' stata inviata una email al tuo indirizzo di posta per completare la procedura.",
      isOpen: true,
      title: "Richiesta inviata correttamente",
    });
  };
  const onResetError = (e) => {
    let err;
    console.log(e);
    if (e.code === "auth/user-not-found") {
      err = "Non abbiamo trovato un account associato a questa mail.";
    } else {
      err = e;
    }
    setStatus({
      err: err,
      isOpen: true,
      title: "Attenzione",
    });
  };
  const onResetButtonPressed = async (values, setSubmitting) => {
    const { email } = values;
    try {
      await props.firebase.resetPassword(email);
      setSubmitting(false);
      onResetSuccess();
    } catch (error) {
      setSubmitting(false);
      onResetError(error);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          //alert(JSON.stringify(values));
          onResetButtonPressed(values, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <VpnKeyIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Recupera Password
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  inputProps={{ style: inputStyle }}
                  id="email"
                  label="Inserisci l'indirizzo email usato per registrarti"
                  name="email"
                  value={values.email}
                  autoComplete="email"
                  autoFocus
                  helperText={touched.email ? errors.email : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Recupera password
                  {isSubmitting && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>

                {status && (
                  <MyDialog
                    isOpen={status.isOpen ? status.isOpen : false}
                    handleClose={handleDialogClose}
                    title={status.title ? status.title : ""}
                    maxWidth="xs"
                  >
                    <Typography component={"span"} variant={"body2"}>
                      {status.regOK ? status.regOK : status.err}
                    </Typography>
                  </MyDialog>
                )}
              </form>
            </div>
          </Container>
        )}
      </Formik>
    </div>
  );
};
export default compose(withRouter, withFirebase, withStyles(styles))(Recovery);
