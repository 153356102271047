import "./styles.css";
import React, { useState, useEffect } from "react";
import { BarChart, Bar, YAxis, CartesianGrid, Tooltip, Cell } from "recharts";

import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import { compose } from "recompose";
import { Grid } from "@material-ui/core";
import COLORS from "./../../const/colors";

function StatisticheBar(props) {
  const [bar, setBar] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const getBarDistribuition = async () => {
      const resp = await props.firebase.getDistribuition(
        props.role,
        props.token
      );
      if (resp.isOk) {
        const d = resp.distribuition.data.distribuition;
        console.log(d);
        setBar([...d]);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
      }
    };

    getBarDistribuition();
  }, [props.firebase, props.token, props.role, error]);
  return (
    <Grid container>
      <Grid item md={12}>
        <Title>Totale delle notizie per categoria</Title>
        <CssBaseline />
      </Grid>
      <Grid item md={12}>
        <BarChart
          width={500}
          height={400}
          data={bar}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="total">
            {bar.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                stroke={COLORS[index]}
                strokeWidth={4}
                fill={COLORS[index]}
              />
            ))}
          </Bar>
        </BarChart>
      </Grid>
    </Grid>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(StatisticheBar);
