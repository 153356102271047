import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import { MyDialog } from "../MyDialog";
import { Formik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  submitButton: {
    marginTop: "10px",
  },
  progress: {
    position: "absolute",
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
function MyProfile(props) {
  const classes = useStyles();

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
  });
  /* const getUserProfile = async () => {
    const userToken = await HandleCookie.getCookie();
    if (userToken) {
      let user = JSON.parse(userToken);
      setProfile(user);
    }
  }; */
  const getUserProfile = () => {
    setProfile(props.currUser);
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const [status, setStatus] = useState([]);
  const handleDialogClose = () => {
    setStatus({ isOpen: false, err: "" });
    if (status.regOK) {
      setStatus({ regOK: false, isOpen: false });
      //props.history.push(LANDING);
    }
  };
  const onResetSuccess = () => {
    setStatus({
      regOK:
        "E' stata inviata una email al tuo indirizzo di posta per completare la procedura.",
      isOpen: true,
      title: "Richiesta inviata correttamente",
    });
  };
  const onResetError = (e) => {
    let err;
    console.log(e);
    if (e.code === "auth/user-not-found") {
      err = "Non abbiamo trovato un account associato a questa mail.";
    } else {
      err = e;
    }
    setStatus({
      err: err,
      isOpen: true,
      title: "Attenzione",
    });
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Paper className={classes.paper}>
            <Title>Il tuo Profilo</Title>
            <CssBaseline />
            <Card>
              <Divider />
              <Formik
                enableReinitialize
                initialValues={{
                  email: authUser.email,
                  firstName: profile.firstName,
                  lastName: profile.lastName,
                  userName: profile.userName,
                }}
                onSubmit={
                  async (values, actions) => {
                    const { email } = values;
                    console.log(values);
                    try {
                      await props.firebase.resetPassword(email);
                      actions.setSubmitting(false);
                      onResetSuccess();
                    } catch (error) {
                      actions.setSubmitting(false);
                      onResetError(error);
                    }
                  }

                  /* (values, actions) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  actions.setSubmitting(false);
                }, 1000);
              } */
                }
              >
                {(props, isSubmitting) => (
                  <form onSubmit={props.handleSubmit}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Nome"
                            margin="dense"
                            name="firstName"
                            variant="outlined"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.firstName}
                            disabled
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Cognome"
                            margin="dense"
                            name="lastName"
                            variant="outlined"
                            disabled
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.lastName}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Username"
                            margin="dense"
                            name="userName"
                            variant="outlined"
                            disabled
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.userName}
                          />
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="email"
                            margin="dense"
                            name="email"
                            variant="outlined"
                            disabled
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.email}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                          >
                            RESET Password
                            {isSubmitting && (
                              <CircularProgress
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                          {status && (
                            <MyDialog
                              isOpen={status.isOpen ? status.isOpen : false}
                              handleClose={handleDialogClose}
                              title={status.title ? status.title : ""}
                              maxWidth="xs"
                            >
                              <Typography component={"span"} variant={"body2"}>
                                {status.regOK ? status.regOK : status.err}
                              </Typography>
                            </MyDialog>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </form>
                )}
              </Formik>
            </Card>
          </Paper>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}
const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(MyProfile);
