import React from "react";
import NavigationAuth from "../NavigationAuth";
import NavigationNotAuth from "../NavigationNotAuth";
import AuthUserContext from "./../../session/context";

const Navigation = () => {
  return (
    <div>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <NavigationAuth authUser={authUser} />
          ) : (
            <NavigationNotAuth />
          )
        }
      </AuthUserContext.Consumer>
    </div>
  );
};

export default Navigation;
