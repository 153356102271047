import axios from "axios";

const API = {
  PUBLIC: axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: false,
  }),
  PRIVATE: (accessToken) =>
    axios.create({
      baseURL: process.env.REACT_APP_BASEURL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      withCredentials: false,
    }),
};
export default API;
