export const navRoutes = [
  {
    role: ["god", "editor"],
    path: "/home",
    icon: "home",
    sidebarName: "Home",
  },
  {
    role: ["god"],
    path: "/utenti",
    icon: "group",
    sidebarName: "Utenti",
  },
  {
    role: ["god", "editor"],
    path: "/modifica-profilo",
    icon: "person",
    sidebarName: "Modifica profilo",
  },
  {
    role: ["god", "editor"],
    path: "/news",
    icon: "library_books",
    sidebarName: "News",
  },
  {
    role: ["god", "editor"],
    path: "/aggiungi-news",
    icon: "post_add",
    sidebarName: "Aggiungi News",
  },
  {
    role: ["god", "editor"],
    path: "/categorie",
    icon: "label",
    sidebarName: "Categorie",
  },
  {
    role: ["god", "editor"],
    path: "/statistiche",
    icon: "equalizer",
    sidebarName: "Statistiche",
  },
  {
    role: ["god", "editor"],
    path: "/problemi",
    icon: "report_problem",
    sidebarName: "Problemi",
  },
];
