import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { Grid, Card, CardContent } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import { LOCALETEXT } from "./../../const/dataGridLocale";
import HandleCookie from "./../../services/cookie";

//import COLUMNS from "./../../const/dataGridCol";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { green, red, yellow } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//import DetailsIcon from "@material-ui/icons/Details";
import EditNews from "./../EditNews/index";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function NewsList(props) {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [CurrNews, setCurrNews] = useState([]);
  const [check, setCheck] = useState(false); //controllo se ho fatto modifiche e scambio il valore
  const [userName, setUserName] = useState("");

  const COLUMNS = [
    { field: "title", headerName: "Titolo", type: "string", flex: 0.8 },

    {
      field: "tags",
      headerName: "Categorie",
      flex: 0.7,
      type: "string",
      valueGetter: (params) => {
        let str = "";
        for (var a = 0; a < params.value.length; a++) {
          str += params.value[a].title;
          if (a !== params.value.length - 1) {
            str += ",";
          }
        }
        return str;
      },
      renderCell: (params) =>
        params.value.map((t) => (
          <div style={{ paddingRight: 5 }}>
            <Chip
              label={t.title}
              color="primary"
              variant="outlined"
              size="small"
            />
          </div>
        )),
    },
    {
      field: "dateTobePublished",
      headerName: "Data di pubblicazione",
      type: "date",
      width: 200,
      renderCell: (params) => (
        <Typography color="textSecondary">
          <em>
            {moment(params.value).format("DD/MM/YY") +
              " alle ore " +
              moment(params.value).format("HH:mm")}
          </em>
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      type: "Number",
      align: "center",
      renderCell: (params) => {
        const checkStatus = () => {
          let s;
          let c;
          switch (params.value) {
            case 0:
              s = "cloud_upload";
              c = { color: yellow[500], align: "center" };
              break;
            case -1:
              s = "cloud_off";
              c = { color: red[500], align: "center" };
              break;

            default:
              s = "cloud_done";
              c = { color: green[500], align: "center" };
              break;
          }
          return { status: s, color: c };
        };
        const { status, color } = checkStatus();
        return <Icon style={color}>{status}</Icon>;
      },
    },
    {
      field: "author",
      headerName: "Autore",

      width: 130,
      renderCell: (params) => (
        <div>
          <Typography color="textSecondary">
            <em>{params.value.userName}</em>
          </Typography>
        </div>
      ),
    },
    {
      field: "dettagli",
      headerName: "Dettagli",
      width: 130,
      type: "Number",
      renderCell: (params) => {
        const onClick = () => {
          /*   const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = {};

          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          }); */
          const result = news.find((obj) => {
            return obj.id === parseInt(selectionModel);
          });

          //return alert(JSON.stringify(thisRow, null, 4));
          // return <EditNews news={thisRow} />;
          setOpen(true);
          setCurrNews(result);
        };

        //return <Button onClick={onClick}>Click</Button>;
        return (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<MoreVertIcon />}
            onClick={onClick}
          >
            dettagli
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    const getNews = async () => {
      const resp = await props.firebase.getNews(props.role, props.token);
      if (resp.isOk) {
        const newsList = resp.news.data.news;
        setNews([...newsList]);
        console.log(newsList);
        setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
        console.log(resp.err.response.data.error);
      }
    };

    getNews();
  }, [props.firebase, props.token, props.role, error, check]);
  useEffect(() => {
    const getTags = async () => {
      const resp = await props.firebase.getTags(props.role, props.token);
      if (resp.isOk) {
        const t = resp.tags.data.tags.map(function (tag) {
          return { id: tag.id, title: tag.title };
        });
        //console.log(t);
        setTags([...t]);
        //setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getTags();
  }, [props.firebase, props.token, props.role, error]);
  useEffect(() => {
    const canEdit = async () => {
      const token = await HandleCookie.getCookie();
      const userName = token.userName;
      setUserName(userName);
    };
    canEdit();
  }, []);

  /*  const updateNewsList = (id) => {
    let NewList = [...drafts];
    for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].id === id) {
        NewList.splice(i, 1);
      }
    }

    setDrafts(NewList);
  }; */
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {news && tags ? (
                  <Card className={classes.root} variant="outlined">
                    <CardContent
                      style={{ height: 700, width: "100%", padding: 0 }}
                    >
                      <DataGrid
                        rows={news}
                        columns={COLUMNS}
                        pageSize={10}
                        components={{
                          LoadingOverlay: CustomLoadingOverlay,
                        }}
                        loading={isLoading}
                        //checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                          setSelectionModel(newSelection.selectionModel);
                          console.log(newSelection.selectionModel);
                        }}
                        selectionModel={selectionModel}
                        localeText={LOCALETEXT}
                      />
                    </CardContent>
                  </Card>
                ) : (
                  <LinearProgress />
                )}
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                  setCheck((prevCheck) => !prevCheck);
                }}
                title={" Dettagli della News"}
                maxWidth="xl"
              >
                <Typography component={"span"} variant={"body2"}>
                  <EditNews
                    news={CurrNews}
                    tagList={tags}
                    role={props.role}
                    userName={userName}
                  />
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}
const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(NewsList);
