import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import HandleCookie from "./../../services/cookie";
import LabelIcon from "@material-ui/icons/Label";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MyDialog } from "./../MyDialog/index";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  myButton: {
    margin: theme.spacing(1),
  },
  root: {
    display: "fluid",
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    position: "absolute",
  },
  editButton: {
    marginTop: "10px",
    margin: theme.spacing(5),
  },
  formControl: {
    marginLeft: "54px",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  when: {
    paddingRight: "12px",
  },
  separator: {
    color: "#dc3545",
  },
});
class AddTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      icon: "",
      title: "",
      order: 0,
      isUpdating: false,
      isOpen: false,
      msg: "",
    };
  }
  componentDidMount() {
    this.setState({
      name: "",
      icon: "",
      title: "",
      order: 0,
      //   isOpen: false,
      //   msg: "",
    });
  }
  updateList = () => {
    /*    let NewList = [...this.props.tagList];
    for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].id === this.state.id) {
        NewList.splice(i, 1);
      }
    }

    this.props.callBack(NewList); */
  };
  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getCredentials = async () => {
    const token = await HandleCookie.getCookie();
    const access = token.access_token;
    const role = token.role;
    const usr = {
      token: access,
      role: role,
    };
    return usr;
  };
  msg = (t) => {
    this.setState({
      msg: t,
    });
  };

  edit = () => {
    this.setState({ isUpdating: true });
  };

  add = async (v) => {
    console.log("ora salvo" + this.state.id);
    const usr = await this.getCredentials();
    //console.log(usr);
    console.log(v);
    const resp = await this.props.firebase.addTag(usr.role, v, usr.token);
    if (resp.isOk) {
      const newTag = resp.tag.data.tag;
      this.props.callBack(newTag);
      console.log(newTag);
      this.msg(resp.tag.data.esito);

      this.setState({
        isOpen: true,
      });
    } else {
      console.log(resp.err.response.data.what);
      this.msg(resp.err.response.data.what);
      this.setState({
        isOpen: true,
      });
    }
    this.setState({ isUpdating: false });
  };

  reset = (p) => {
    console.log(p);
    this.setState({ isUpdating: false });
    //this.formik.current.resetForm();
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Formik
              enableReinitialize
              initialValues={{
                name: this.state.name,
                title: this.state.title,
                icon: this.state.icon,
                order: this.state.order,
              }}
              onSubmit={async (values, actions) => {
                //console.log(values);
                this.add(values);
                actions.resetForm({});
              }}
              onReset={(values, actions) => {
                this.setState({ isUpdating: false });
              }}
            >
              {(props, isSubmitting) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item md={1} xs={12}>
                      <Typography variant="h3" component="h2" color="primary">
                        <LabelIcon />
                      </Typography>
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <TextField
                        fullWidth
                        label="Ordine"
                        margin="dense"
                        name="order"
                        variant="outlined"
                        type="number"
                        inputProps={{ min: "0", step: "1" }}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.order}
                        disabled={!this.state.isUpdating}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        fullWidth
                        label="Nome"
                        margin="dense"
                        name="name"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.name}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Titolo"
                        margin="dense"
                        name="title"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.title}
                      />
                    </Grid>

                    <Grid item md={2} xs={6}>
                      <TextField
                        fullWidth
                        label="Icona"
                        margin="dense"
                        name="icon"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.icon.split(".").pop()}
                      />
                    </Grid>
                    <Grid item md={1} xs={6}>
                      <Icon
                        style={{ color: "red", fontSize: 30, marginTop: 10 }}
                      >
                        {props.values.icon.split(".").pop()}
                      </Icon>
                    </Grid>
                    {this.props.role !== "editor" ? (
                      <Grid item md={2} xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          className={classes.editButton}
                          startIcon={<SaveIcon />}
                          disabled={!this.state.isUpdating}
                        >
                          Salva
                          {isSubmitting && (
                            <CircularProgress
                              size={30}
                              className={classes.progress}
                            />
                          )}
                        </Button>
                        {this.state.isUpdating ? (
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.editButton}
                            startIcon={<UndoIcon />}
                            disabled={!this.state.isUpdating}
                            onClick={props.handleReset}
                          >
                            Annulla
                          </Button>
                        ) : null}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
          {this.props.role !== "editor" ? (
            <CardActions>
              <Grid container spacing={3}>
                <Grid item md={2} xs={6}>
                  {this.state.deletedAt ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.myButton}
                      startIcon={<UndoIcon />}
                      disabled={this.state.isUpdating}
                      onClick={() => {
                        this.undo();
                      }}
                    >
                      Annulla
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.myButton}
                      startIcon={<EditIcon />}
                      disabled={this.state.isUpdating}
                      onClick={() => {
                        this.edit();
                      }}
                    >
                      Aggiungi
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardActions>
          ) : (
            ""
          )}
          <MyDialog
            isOpen={this.state.isOpen}
            handleClose={() => {
              this.setState({ isOpen: false });
            }}
            title={"Esito operazione"}
            maxWidth="xs"
          >
            {this.state.msg}
          </MyDialog>
        </Card>
      </Grid>
    );
  }
}
export default compose(withStyles(styles), withFirebase)(AddTag);
