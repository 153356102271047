import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function MyAvatar(props) {
  const classes = useStyles();
  /* const [userName, setUser] = useState("");

  useEffect(() => {
    let un = welcome();
    setUser(un);
  }, []); */

  return (
    <div className={classes.root}>
      <Avatar color="inherit">{props.userName}</Avatar>
    </div>
  );
}
export default MyAvatar;
