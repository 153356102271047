import React, { Component } from "react";
import "date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import HandleCookie from "./../../services/cookie";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PublishIcon from "@material-ui/icons/Publish";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { Formik, Field } from "formik";
import UndoIcon from "@material-ui/icons/Undo";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import Chip from "@material-ui/core/Chip";
import NewLineText from "../NewLineText";
import { green, red } from "@material-ui/core/colors";

const styles = (theme) => ({
  myButton: {
    margin: theme.spacing(1),
  },
  root: {
    display: "fluid",
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    position: "absolute",
  },
  editButton: {
    marginTop: "10px",
  },

  formControl: {
    marginLeft: "54px",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  when: {
    paddingRight: "12px",
  },
  separator: {
    color: "#dc3545",
  },
  timePicker: {
    color: "primary",
  },
});
const validation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Campo obbligatorio")
    .min(3, "Almeno tre lettere"),
  body: Yup.string()
    .trim()
    .max(190, "Hai superato il limite massimo di caratteri")
    .min(3, "Almeno tre lettere")
    .required("Campo obbligatorio"),
  link: Yup.string()
    .trim()
    /*  .matches(
      /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Inserisci un link corretto"
    ) */
    .url("Inserire un link corretto")
    .required("Campo obbligatorio")
    .min(4, "Almeno quattro lettere"),
  tags: Yup.array()
    .min(1, "Scegliere almeno una categoria")
    .required("Scegliere almeno una categoria"),

  dateTobePublished: Yup.date().nullable().required("Campo obbligatorio"),
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class EditNews extends Component {
  constructor(props) {
    super(props);

    const { news } = props;
    this.state = {
      id: news.id,
      title: news.title,
      body: news.body,
      link: news.link,
      dateTobePublished: news.dateTobePublished,
      author: news.author,
      updatedAt: news.updated_at,
      savedTags: news.tags,
      isUpdating: false,
      msg: "",
      color: {},
    };
  }
  componentDidMount() {
    const { news } = this.props;
    this.setState({
      id: news.id,
      title: news.title,
      body: news.body,
      link: news.link,
      dateTobePublished: news.dateTobePublished,
      author: news.author,
      updatedAt: news.updated_at,
      savedTags: news.tags,
      deleted: false,

      //isDraft: false,

      //   isOpen: false,
      //   msg: "",
    });
  }

  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  formatDate = (date) => {
    let data = moment(date).format("DD/MM/YYYY");
    let ora = moment(date).format("HH:mm:ss");
    return data + " alle ore " + ora;
  };

  getCredentials = async () => {
    const token = await HandleCookie.getCookie();
    const access = token.access_token;
    const role = token.role;
    const usr = {
      token: access,
      role: role,
    };
    return usr;
  };
  msg = (t) => {
    this.setState({
      msg: t,
    });
  };

  edit = () => {
    this.setState({ isUpdating: true });
  };
  publish = async (v) => {
    console.log("ora pubblico");

    const usr = await this.getCredentials();
    const resp = await this.props.firebase.editNews(usr.role, v, usr.token);
    if (resp.isOk) {
      const newNews = resp.news.data.news;
      //this.props.callBack(newDraft);
      console.log(newNews);
      this.msg(resp.news.data.esito);
      this.setState({
        color: { color: green[500] },
      });
    } else {
      const err = resp.err.response.data.what;
      this.msg(err.replace(/,/g, "\n"));
      this.setState({
        color: { color: red[500] },
      });
    }
    this.setState({ isUpdating: false });
  };

  delete = async (id) => {
    console.log(id);
    const usr = await this.getCredentials();
    const resp = await this.props.firebase.deleteNews(usr.role, usr.token, id);
    if (resp.isOk) {
      console.log(resp.news.data.esito);
      this.msg(resp.news.data.esito);
      this.setState({
        isOpen: true,
        deleted: true,
        color: { color: green[500] },
      });
    } else {
      console.log(resp.err.response.data.esito);
      this.msg(resp.err.response.data.esito);
      this.setState({
        isOpen: true,
        color: { color: red[500] },
      });
    }
  };

  reset = (p) => {
    console.log(p);
    this.setState({ isUpdating: false });
    //this.formik.current.resetForm();
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                id: this.state.id,
                body: this.state.body,
                title: this.state.title,
                link: this.state.link,
                tags: this.state.savedTags,
                dateTobePublished: this.state.dateTobePublished,
              }}
              validationSchema={validation}
              onSubmit={async (values, actions) => {
                console.log(values);

                this.publish(values);
                this.setState({ isUpdating: false });
              }}
              onReset={(values, actions) => {
                this.setState({ isUpdating: false });
              }}
            >
              {(props, isSubmitting, setFieldValue, values) => (
                <form onSubmit={props.handleSubmit} noValidate>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Titolo"
                            margin="dense"
                            name="title"
                            variant="outlined"
                            type="text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.title}
                            placeholder={"Scrivere il titolo della notizia"}
                            disabled={!this.state.isUpdating}
                            helperText={
                              props.touched.title ? props.errors.title : ""
                            }
                            error={
                              props.touched.title && Boolean(props.errors.title)
                            }
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Link"
                            margin="dense"
                            name="link"
                            variant="outlined"
                            disabled={!this.state.isUpdating}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.link}
                            placeholder={"Scrivere il link della notizia"}
                            helperText={
                              props.touched.link ? props.errors.link : ""
                            }
                            error={
                              props.touched.link && Boolean(props.errors.link)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        label="Corpo della notizia"
                        margin="dense"
                        name="body"
                        variant="outlined"
                        multiline
                        rows={4}
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.body}
                        placeholder={
                          "Scrivere il corpo della notizia (max 190 caratteri)"
                        }
                        helperText={props.touched.body ? props.errors.body : ""}
                        error={props.touched.body && Boolean(props.errors.body)}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        name="tags"
                        multiple
                        key={this.state.id}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        value={props.values.tags}
                        onChange={(e, value) => {
                          props.setFieldValue("tags", value);
                        }}
                        disabled={!this.state.isUpdating}
                        options={this.props.tagList}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              color="primary"
                              checked={selected}
                            />
                            {option.title}
                          </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Categorie"
                            placeholder="Aggiungi categorie"
                            helperText={
                              props.touched.tags ? props.errors.tags : ""
                            }
                            error={
                              props.touched.tags && Boolean(props.errors.tags)
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.title}
                              {...getTagProps({ index })}
                              color="primary"
                              variant="outlined"
                            />
                          ))
                        }
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Field
                        name="dateTobePublished"
                        component={DatePickerField}
                        disabled={!this.state.isUpdating}
                        helperText={
                          props.touched.dateTobePublished
                            ? props.errors.dateTobePublished
                            : ""
                        }
                        error={
                          props.touched.dateTobePublished &&
                          Boolean(props.errors.dateTobePublished)
                        }
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <ButtonGroup
                        color="primary"
                        variant="text"
                        aria-label="text primary button group"
                        disabled={!this.state.isUpdating}
                      >
                        <Button
                          type="submit"
                          className={classes.editButton}
                          startIcon={<UndoIcon />}
                          disabled={!this.state.isUpdating}
                          onClick={props.handleReset}
                        >
                          Annulla
                        </Button>{" "}
                        <Button
                          type="submit"
                          className={classes.editButton}
                          startIcon={<PublishIcon />}
                          disabled={!this.state.isUpdating}
                        >
                          Pubblica
                          {isSubmitting && (
                            <CircularProgress
                              size={30}
                              className={classes.progress}
                            />
                          )}
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item md={12}>
                      <span style={this.state.color}>
                        <NewLineText text={this.state.msg} />
                      </span>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
          <CardActions>
            <Grid container spacing={1}>
              {this.props.userName === this.state.author.userName ||
              this.props.role === "god" ? (
                <Grid item md={4} xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<EditIcon />}
                    disabled={this.state.isUpdating}
                    onClick={() => {
                      this.edit();
                    }}
                  >
                    Modifica News
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<DeleteIcon />}
                    disabled={this.state.isUpdating}
                    onClick={() => {
                      this.delete(this.state.id);
                    }}
                  >
                    Elimina
                  </Button>
                </Grid>
              ) : (
                <Grid item md={4} xs={6}></Grid>
              )}
              <Grid item md={2} xs={6}></Grid>
              <Grid item container md={6} xs={12} alignContent="flex-end">
                <Box width={1} textAlign="right">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    display="inline"
                    className={classes.when}
                  >
                    <br />
                    Scritto da <strong>{this.state.author.userName} </strong>
                    <span className={classes.separator}>&nbsp;|&nbsp;</span>
                    Ultima modifica il {this.formatDate(this.state.updatedAt)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

const DatePickerField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <KeyboardDateTimePicker
      disablePast
      inputVariant="outlined"
      variant="outlined"
      label="Data di pubblicazione"
      cancelLabel="Annulla"
      ampm={false}
      invalidDateMessage="Selezionare un formato di data valido gg/mm/aaaa"
      minDateMessage="La data non può essere inferiore ad oggi"
      name={field.name}
      value={field.value}
      format="dd/MM/yyyy HH:mm"
      helperText={currentError}
      error={Boolean(currentError)}
      onChange={(date) => form.setFieldValue(field.name, date, true)}
      {...other}
    />
  );
};
export default compose(withStyles(styles), withFirebase)(EditNews);
