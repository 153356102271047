import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as EXTROUTES from "../../const/extRoutes";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withFirebase } from "../../services/";
import { Formik } from "formik";
import * as Yup from "yup";
import { HOME } from "./../../const/extRoutes";
import { MyDialog } from "../MyDialog";
import HandleCookie from "./../../services/cookie";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progess: {
    position: "absolute",
  },
});
const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Inserisci solo lettere")
    .required("Campo obbligatorio")
    .min(3, "Almeno tre lettere"),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Inserisci solo lettere")
    .min(3, "Almeno tre lettere")
    .required("Campo obbligatorio"),
  userName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Inserisci solo lettere")
    .matches(/^\S*$/, " Non usare spazi")
    .required("Campo obbligatorio")
    .min(4, "Almeno quattro lettere"),

  email: Yup.string()
    .email("Inserisci una mail valida")
    .required("Campo obbligatorio")
    .trim()
    .matches(
      /^.*(@scuola.alto-adige.it|@provincia.bz.it)$/,
      "Usa la mail istituzionale"
    ),
  password: Yup.string()
    .min(8, "La password deve essere di almeno 8 caratteri")
    .required("Campo obbligatorio")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
      "Almeno 8 caratteri, una lettera maiuscola, una lettera maiuscola, un carattere speciale"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Le password non corrispondono")
    .required("Campo obbligatorio"),
});
const SignUp = (props) => {
  const { classes } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState([]);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleDialogClose = () => {
    setStatus({ isOpen: false, err: "" });
    if (status.regOK) {
      setStatus({ regOK: false, isOpen: false });
      props.history.push(HOME);
    }
  };
  const onRegSuccess = () => {
    setStatus({
      regOK: "Benvenuto/a nel sito con email e la password da te scelta.",
      isOpen: true,
      title: "Registrazione effettuata correttamente",
    });
  };
  const onRegError = (e) => {
    let err;
    console.log(e);
    if (e.code === "auth/email-already-in-use") {
      err = "Indirizzo email già in uso. ";
    } else {
      err = e.code + " " + e.message;
    }
    setStatus({
      err: err,
      isOpen: true,
      title: "Attenzione",
    });
  };
  const onSignupButtonPressed = async (values, setSubmitting) => {
    const { email, password } = values;
    try {
      const response = await props.firebase.signupWithEmail(email, password);
      if (response.user.uid) {
        const { uid } = response.user;
        const userData = { ...values, uid };

        const regUser = await props.firebase.createNewUser(userData);
        if (!regUser.isAuthError) {
          setSubmitting(false);
          const token = await HandleCookie.setCookie(regUser.user.data);
          if (token) onRegSuccess();
        } else {
          setSubmitting(false);
          onRegError(regUser.err);
          var deleteUser = props.firebase.currentUser();
          deleteUser
            .delete()
            .then(function () {
              // User deleted.
            })
            .catch(function (error) {
              // An error happened.
            });
        }
      }
    } catch (error) {
      setSubmitting(false);

      onRegError(error);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          //alert(JSON.stringify(values));
          onSignupButtonPressed(values, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <PersonAddIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Registrati
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ style: inputStyle }}
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="Nome"
                      name="firstName"
                      value={values.firstName}
                      autoComplete="firstName"
                      helperText={touched.firstName ? errors.firstName : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ style: inputStyle }}
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Cognome"
                      name="lastName"
                      autoComplete="lastName"
                      value={values.lastName}
                      helperText={touched.lastName ? errors.lastName : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ style: inputStyle }}
                      variant="outlined"
                      required
                      fullWidth
                      id="userName"
                      label="User Name"
                      name="userName"
                      value={values.userName}
                      helperText={touched.userName ? errors.userName : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userName && Boolean(errors.userName)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      inputProps={{ style: inputStyle }}
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Indirizzo email"
                      name="email"
                      value={values.email}
                      autoComplete="email"
                      helperText={touched.email ? errors.email : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      inputProps={{ style: inputStyle }}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        style: inputStyle,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={values.password}
                      autoComplete="current-password"
                      helperText={touched.password ? errors.password : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      inputProps={{ style: inputStyle }}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        style: inputStyle,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Conferma la Password"
                      type={showPassword ? "text" : "password"}
                      id="confirmPassword"
                      value={values.confirmPassword}
                      helperText={
                        touched.confirmPassword ? errors.confirmPassword : ""
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Registrati
                  {isSubmitting && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
                {status && (
                  <MyDialog
                    isOpen={status.isOpen ? status.isOpen : false}
                    handleClose={handleDialogClose}
                    title={status.title ? status.title : ""}
                    maxWidth="xs"
                  >
                    <Typography component={"span"} variant={"body2"}>
                      {status.regOK ? status.regOK : status.err}
                    </Typography>
                  </MyDialog>
                )}

                <Grid container justify="flex-end">
                  <Grid item xs={6}>
                    <Link href={EXTROUTES.LANDING} variant="body2">
                      {"Hai già un account?"}
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Link href={EXTROUTES.PASSWORD_FORGET} variant="body2">
                      {"Password dimenticata?"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(withFirebase(withStyles(styles)(SignUp)));
