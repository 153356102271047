export const LOCALETEXT = {
  filterOperatorContains: "contiene",
  filterOperatorEquals: "è uguale a",
  filterOperatorStartsWith: "inizia per",
  filterOperatorEndsWith: "finisce per",
  filterOperatorIs: "è",
  filterOperatorNot: "non è",
  filterOperatorAfter: "è dopo",
  filterOperatorOnOrAfter: "a partire dal",
  filterOperatorBefore: "è prima",
  filterOperatorOnOrBefore: "al più tardi entro",
  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostra colonne",
  columnMenuFilter: "Filtri",
  columnMenuHideColumn: "Nascondi",
  columnMenuUnsort: "Annulla",
  columnMenuSortAsc: "Ordinamento CRESC",
  columnMenuSortDesc: "Ordinamento DECRESC",
  // Columns panel text
  columnsPanelTextFieldLabel: "Trova Colonna",
  columnsPanelTextFieldPlaceholder: "Intestazione",
  columnsPanelDragIconLabel: "Riordina Colonna",
  columnsPanelShowAllButton: "Mostra tutto",
  columnsPanelHideAllButton: "Nascondi tutto",

  // Filter panel text
  filterPanelAddFilter: "Aggiungi Filtro",
  filterPanelDeleteIconLabel: "Elimina",
  filterPanelOperators: "Operazioni",
  filterPanelOperatorAnd: "E",
  filterPanelOperatorOr: "Oppure",
  filterPanelColumns: "Colonne",
  filterPanelInputLabel: "Valore",
  filterPanelInputPlaceholder: "Valore del filtro",
  //rows
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} righe selezionate`
      : `${count.toLocaleString()} riga selezionata`,

  // Total rows footer text
  footerTotalRows: "Righe totali:",
};
