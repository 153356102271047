import React, { useState, useEffect } from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Icon, makeStyles, MenuItem, MenuList } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { navRoutes } from "../../const/navRoutes";
import ListItemIcon from "@material-ui/core/ListItemIcon";
//import HandleCookie from "./../../services/cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    active: {
      color: theme.primary,
    },
    notActive: {
      color: "blue",
    },
  },
}));

const SideBar = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const [isActive, setActive] = useState("");

  useEffect(() => {
    return history.listen((location) => {
      setActive(location.pathname);
    });
  }, [history]);

  const activeRoute = (routeName) => {
    return isActive === routeName ? true : false;
  };
  const roleLinks = (role) => {
    return role.includes(props.role);
  };
  return (
    <div className={classes.root}>
      <MenuList>
        {navRoutes.map((prop, key) => {
          return roleLinks(prop.role) ? (
            <NavLink
              to={prop.path}
              style={{ textDecoration: "none", color: "#9ea7aa" }}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
              key={key}
            >
              <MenuItem selected={activeRoute(prop.path)}>
                <ListItemIcon>
                  <Icon
                    color={
                      history.location.pathname === prop.path
                        ? "primary"
                        : "secondary"
                    }
                  >
                    {prop.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={prop.sidebarName} />
              </MenuItem>
            </NavLink>
          ) : (
            ""
          );
        })}
      </MenuList>
    </div>
  );
};
export default withRouter(SideBar);
