import firebase from "firebase/app";
import firebaseConfig from "./firebaseConfig";
import API from "./api";
import "firebase/firestore";
import "firebase/auth";
import * as BACKEND from "../const/backendRoutes";
/* class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.db = firebase.firestore();
    this.auth = firebase.auth();
  }
  // *** Auth API ***

  //registro utenti
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  //faccio il login con mail e psw
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  //faccio il logout
  doSignOut = () => this.auth.signOut();
  //chiedo il reset della password
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  //faccio l'update della password
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  // *** DB USER API ***
  //registro gli utenti nel db
  createNewUser = (userData) => {
    userData.role = "editor";
    this.db.collection("users").doc(`${userData.uid}`).set(userData);
  };
  //trovo un utente
  findUser = (userUID) => {
    this.db.collection("users").doc(`${userUID}`).limit(1).get();
  };
  //trovo tutti gli utenti
  getUsers = () => {
    this.db.collection("users").get();
  };
} */
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
//const db = firebase.firestore();
const Firebase = {
  // auth
  loginWithEmail: (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  },
  signOut: async () => {
    return await auth.signOut();
  },
  resetPassword: async (mail) => {
    //auth.languageCode = "it";
    //console.log(mail);
    try {
      return auth.sendPasswordResetEmail(mail);
    } catch (err) {
      return err;
    }
  },
  checkUserAuth: (user) => {
    return auth.onAuthStateChanged(user);
  },
  getIdToken: async () => {
    return auth.currentUser.getIdToken(true);
  },
  currentUser: () => {
    return auth.currentUser;
  },
  //creo un nuovo utente
  createNewUser: async (userData) => {
    const newUser = {
      firstName:
        userData.firstName.charAt(0).toUpperCase() +
        userData.firstName.slice(1),
      lastName:
        userData.lastName.charAt(0).toUpperCase() + userData.lastName.slice(1),
      userName: userData.userName,
      email: userData.email,
      userId: userData.uid,
      role: "editor",
    };
    try {
      const user = await API.PUBLIC.post(BACKEND.SIGNUP, newUser);
      return { user, isAuthError: false };
    } catch (err) {
      return { err, isAuthError: true };
    }
  },
  //trovo un utente
  findUser: async (userUID) => {
    const data = { uid: userUID };
    try {
      const user = await API.PUBLIC.post(BACKEND.LOGIN, data);
      return { user, isAuthError: false };
    } catch (err) {
      return { err, isAuthError: true };
    }
  },
  //trovo tutti gli utenti
  getUsers: async (role, accessToken) => {
    const data = { role: role };

    try {
      const users = await API.PRIVATE(accessToken).post(BACKEND.USERS, data);
      return { users, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //elimino un utente
  deleteUser: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const user = await API.PRIVATE(accessToken).put(
        BACKEND.DELETE + id,
        data
      );
      return { user, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //annullo eliminazione utente
  undoDelete: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const user = await API.PRIVATE(accessToken).put(
        BACKEND.UNDO_DELETE + id,
        data
      );
      return { user, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //elimino definitivamente utente
  permDelete: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const user = await API.PRIVATE(accessToken).put(
        BACKEND.PERM_DELETE + id,
        data
      );
      return { user, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //modifico un utente
  editUser: async (role, values, accessToken) => {
    let editedUser = {
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName:
        values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1),
      userName: values.userName,
      email: values.email,
      userId: values.userId,
      role: values.role,
    };
    const data = {
      role: role,
      user: editedUser,
    };
    try {
      const user = await API.PRIVATE(accessToken).post(BACKEND.EDIT_USER, data);
      return { user, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //GESTIONE CATEGORIE
  //recupero le categorie
  getTags: async (role, accessToken) => {
    const data = { role: role };

    try {
      const tags = await API.PRIVATE(accessToken).post(BACKEND.SHOW_TAGS, data);
      return { tags, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //modifico la categoria
  editTag: async (role, values, accessToken) => {
    let editedTag = {
      name: values.name,
      title: values.title,
      icon: values.icon.split(".").pop(),
      order: values.order,
      id: values.id,
    };
    const data = {
      role: role,
      tag: editedTag,
    };
    try {
      const tag = await API.PRIVATE(accessToken).post(BACKEND.EDIT_TAG, data);
      return { tag, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //aggiungo la categoria
  addTag: async (role, values, accessToken) => {
    let newTag = {
      name: values.name,
      title: values.title,
      icon: values.icon.split(".").pop(),
      order: values.order,
    };
    const data = {
      role: role,
      tag: newTag,
    };
    try {
      const tag = await API.PRIVATE(accessToken).post(BACKEND.ADD_TAG, data);
      return { tag, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //elimino una categoria
  deleteTag: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const tag = await API.PRIVATE(accessToken).put(
        BACKEND.DELETE_TAG + id,
        data
      );
      return { tag, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //GESTIONE  BOZZE
  //recupero le bozze
  getDrafts: async (role, accessToken) => {
    const data = { role: role };

    try {
      const drafts = await API.PRIVATE(accessToken).post(
        BACKEND.SHOW_DRAFTS,
        data
      );
      return { drafts, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //aggiungo una bozza
  addDraft: async (role, values, accessToken) => {
    let newDraft = {
      body: values.body,
      title: values.title,
      link: values.link,
      tags: values.tags,
      dateTobePublished: values.dateTobePublished,
    };

    const data = {
      role: role,
      draft: newDraft,
    };
    try {
      const draft = await API.PRIVATE(accessToken).post(
        BACKEND.ADD_DRAFT,
        data
      );
      return { draft, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //modifico bozza
  editDraft: async (role, values, accessToken) => {
    let newDraft = {
      id: values.id,
      body: values.body,
      title: values.title,
      link: values.link,
      tags: values.tags,
      dateTobePublished: values.dateTobePublished,
    };

    const data = {
      role: role,
      draft: newDraft,
    };
    try {
      const draft = await API.PRIVATE(accessToken).post(
        BACKEND.EDIT_DRAFT,
        data
      );
      return { draft, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //elimino bozza
  deleteDraft: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const draft = await API.PRIVATE(accessToken).put(
        BACKEND.DELETE_DRAFT + id,
        data
      );
      return { draft, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //GESTIONE  NEWS
  //recupero le news
  getNews: async (role, accessToken) => {
    const data = { role: role };

    try {
      const news = await API.PRIVATE(accessToken).post(BACKEND.SHOW_NEWS, data);
      return { news, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //recupero le ultimi 5 news pubblicate
  getLastNews: async (role, accessToken) => {
    const data = { role: role };

    try {
      const news = await API.PRIVATE(accessToken).post(BACKEND.LAST_NEWS, data);
      return { news, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //aggiungo una news
  addNews: async (role, values, accessToken) => {
    let newNews = {
      body: values.body,
      title: values.title,
      link: values.link,
      tags: values.tags,
      dateTobePublished: values.dateTobePublished,
    };

    const data = {
      role: role,
      news: newNews,
    };
    try {
      const news = await API.PRIVATE(accessToken).post(BACKEND.ADD_NEWS, data);
      return { news, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //modifico bozza
  editNews: async (role, values, accessToken) => {
    let newNews = {
      id: values.id,
      body: values.body,
      title: values.title,
      link: values.link,
      tags: values.tags,
      dateTobePublished: values.dateTobePublished,
    };

    const data = {
      role: role,
      news: newNews,
    };
    try {
      const news = await API.PRIVATE(accessToken).post(BACKEND.EDIT_NEWS, data);
      return { news, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //elimino bozza
  deleteNews: async (role, accessToken, id) => {
    const data = {
      role: role,
    };
    try {
      const news = await API.PRIVATE(accessToken).put(
        BACKEND.DELETE_NEWS + id,
        data
      );
      return { news, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //GESTIONE STATISTICHE
  //distribuzione news
  getDistribuition: async (role, accessToken) => {
    const data = { role: role };

    try {
      const distribuition = await API.PRIVATE(accessToken).post(
        BACKEND.DISTRIBUITION,
        data
      );
      return { distribuition, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //conto notizie
  getNewsCount: async (role, accessToken) => {
    const data = { role: role };

    try {
      const newsCount = await API.PRIVATE(accessToken).post(
        BACKEND.NEWS_COUNT,
        data
      );
      return { newsCount, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
  //stats generali app
  getAppStats: async (role, accessToken) => {
    const data = { role: role };

    try {
      const stats = await API.PRIVATE(accessToken).post(
        BACKEND.STATS_APP,
        data
      );
      return { stats, isOk: true };
    } catch (err) {
      return { err, isOk: false };
    }
  },
};

//vedo gli utenti in realtime
/* streamUsers: (observer) => {
    return db.collection("users").orderBy("createdAt").onSnapshot(observer); 
  },*/
//UPDATE di un campo utente
/*   edituser: async (userId, values) => {
    let user = db.collection("users").doc(userId);
    return user.update({
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName:
        values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1),
      userName: values.userName,
      email: values.email,
      updatedAt: new Date().toISOString(),
      userId: values.userId,
      role: values.role,
    });
  },
}; */
export default Firebase;
