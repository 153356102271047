import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import * as moment from "moment";
import Box from "@material-ui/core/Box";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import HandleCookie from "./../../services/cookie";
import Chip from "@material-ui/core/Chip";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Select,
  InputLabel,
  Button,
  Grid,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Formik } from "formik";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MyDialog } from "./../MyDialog/index";
const styles = (theme) => ({
  myButton: {
    margin: theme.spacing(1),
  },
  root: {
    display: "fluid",
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    position: "absolute",
  },
  editButton: {
    marginTop: "10px",
    margin: theme.spacing(5),
  },
  formControl: {
    marginLeft: "54px",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  when: {
    paddingRight: "12px",
  },
  separator: {
    color: "#dc3545",
  },
});
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      id: "",
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      isUpdating: false,
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
      isOpen: false,
      msg: "",
      permDeleted: false,
    };
  }
  componentDidMount() {
    this.setState({
      userName: this.props.user.userName,
      id: this.props.user.userId,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      role: this.props.user.role,
      email: this.props.user.email,
      createdAt: this.props.user.created_at,
      updatedAt: this.props.user.updated_at ? this.props.user.updated_at : "",
      deletedAt: this.props.user.deleted_at ? this.props.user.deleted_at : "",
      isOpen: false,
      msg: "",
      permDeleted: false,
    });
    //console.log(this.state.createdAt);
  }
  updateList = () => {
    let NewList = [...this.props.userList];
    for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].userId === this.state.id) {
        NewList.splice(i, 1);
      }
    }

    this.props.callBack(NewList);
  };

  getCredentials = async () => {
    const token = await HandleCookie.getCookie();
    const access = token.access_token;
    const role = token.role;
    const usr = {
      token: access,
      role: role,
    };
    return usr;
  };
  msg = (t) => {
    this.setState({
      msg: t,
    });
  };

  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  edit = () => {
    this.setState({ isUpdating: true });
  };
  undo = async () => {
    const usr = await this.getCredentials();
    const resp = await this.props.firebase.undoDelete(
      usr.role,
      usr.token,
      this.state.id
    );
    if (resp.isOk) {
      console.log(resp.user.data.esito);
      this.msg(resp.user.data.esito);
      this.setState({
        isOpen: true,
        deletedAt: null,
        permDeleted: false,
      });
    } else {
      console.log(resp.err.response.data.esito);
      this.msg(resp.err.response.data.esito);
      this.setState({
        isOpen: true,
        permDeleted: false,
      });
    }
  };
  delete = async () => {
    console.log("ora cancello" + this.state.id);
    const usr = await this.getCredentials();
    const resp = await this.props.firebase.deleteUser(
      usr.role,
      usr.token,
      this.state.id
    );
    if (resp.isOk) {
      console.log(resp.user.data.esito);
      this.msg(resp.user.data.esito);
      this.setState({
        deletedAt: resp.user.data.user.deleted_at,
        isOpen: true,
        permDeleted: false,
      });
    } else {
      console.log(resp.err.response.data.esito);
      this.msg(resp.err.response.data.esito);
      this.setState({
        isOpen: true,
        permDeleted: false,
      });
    }
  };
  //cancello definifitamente
  permDelete = async () => {
    console.log("ora cancello" + this.state.id);
    const usr = await this.getCredentials();
    const resp = await this.props.firebase.permDelete(
      usr.role,
      usr.token,
      this.state.id
    );
    if (resp.isOk) {
      console.log(resp.user.data.esito);
      this.msg(resp.user.data.esito);
      this.setState({
        isOpen: true,
        permDeleted: true,
      });
    } else {
      console.log(resp.err.response.data.esito);
      this.msg(resp.err.response.data.esito);
      this.setState({
        isOpen: true,
        permDeleted: false,
      });
    }
  };
  update = async (v) => {
    console.log("ora salvo" + this.state.id);
    const usr = await this.getCredentials();
    //console.log(usr);
    //console.log(v);
    const resp = await this.props.firebase.editUser(usr.role, v, usr.token);
    if (resp.isOk) {
      const updatedUser = resp.user.data.user;
      console.log(resp.user.data.user);
      this.msg(resp.user.data.esito);

      this.setState({
        userName: updatedUser.userName,
        id: updatedUser.userId,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        role: updatedUser.role,
        email: updatedUser.email,
        createdAt: updatedUser.created_at,
        updatedAt: updatedUser.updated_at,
        isOpen: true,
        permDeleted: false,
      });
    } else {
      console.log(resp.err.response.data.what);
      this.msg(resp.err.response.data.what);
      this.setState({
        isOpen: true,
        permDeleted: false,
      });
    }
    this.setState({ isUpdating: false });
  };
  formatDate = (date) => {
    let data = moment(date).format("DD/MM/YYYY");
    let ora = moment(date).format("HH:mm:ss");
    return data + " alle ore " + ora;
  };
  reset = (p) => {
    console.log(p);
    this.setState({ isUpdating: false });
    //this.formik.current.resetForm();
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" color="primary">
              <AssignmentIndIcon />
              {this.capitalize(this.state.userName)}{" "}
              {this.state.deletedAt ? (
                <Chip
                  label="Utente eliminato"
                  disabled
                  variant="outlined"
                  color="primary"
                />
              ) : null}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={{
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                userName: this.state.userName,
                role: this.state.role,
                createdAt: this.state.createdAt,
                updatedAt: this.state.updatedAt,
                userId: this.state.id,
              }}
              onSubmit={async (values, actions) => {
                //console.log(values);
                this.update(values);
              }}
              onReset={(values, actions) => {
                this.setState({ isUpdating: false });
              }}
            >
              {(props, isSubmitting) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nome"
                        margin="dense"
                        name="firstName"
                        variant="outlined"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        disabled={!this.state.isUpdating}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Cognome"
                        margin="dense"
                        name="lastName"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Username"
                        margin="dense"
                        name="userName"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.userName}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="role-label">Ruolo</InputLabel>

                        <Select
                          labelId="role-label"
                          fullWidth
                          label="Ruolo"
                          margin="dense"
                          name="role"
                          variant="outlined"
                          disabled={!this.state.isUpdating}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.role}
                        >
                          <MenuItem value={"editor"}>Editor</MenuItem>
                          <MenuItem value={"god"}>Admin</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="email"
                        margin="dense"
                        name="email"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.editButton}
                        startIcon={<SaveIcon />}
                        disabled={!this.state.isUpdating}
                      >
                        Aggiorna
                        {isSubmitting && (
                          <CircularProgress
                            size={30}
                            className={classes.progress}
                          />
                        )}
                      </Button>
                      {this.state.isUpdating ? (
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          className={classes.editButton}
                          startIcon={<UndoIcon />}
                          disabled={!this.state.isUpdating}
                          onClick={props.handleReset}
                        >
                          Annulla&nbsp;&nbsp;&nbsp;
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
          <CardActions>
            <Grid container spacing={3}>
              <Grid item md={2} xs={6}>
                {this.state.deletedAt ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<UndoIcon />}
                    disabled={this.state.isUpdating}
                    onClick={() => {
                      this.undo();
                    }}
                  >
                    Annulla
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<EditIcon />}
                    disabled={this.state.isUpdating}
                    onClick={() => {
                      this.edit();
                    }}
                  >
                    Modifica
                  </Button>
                )}
              </Grid>
              <Grid item md={2} xs={6}>
                {this.state.deletedAt ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      this.permDelete();
                    }}
                  >
                    Definitivamente
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      this.delete();
                    }}
                  >
                    Elimina
                  </Button>
                )}
              </Grid>
              <Grid item container md={8} xs={12} alignContent="flex-end">
                <Box width={1} textAlign="right">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    display="inline"
                    className={classes.when}
                  >
                    <br /> Creato il {this.formatDate(this.state.createdAt)}{" "}
                    <span className={classes.separator}>&nbsp;|&nbsp;</span>
                    {this.state.updatedAt
                      ? this.state.deletedAt
                        ? " Eliminato il " +
                          this.formatDate(this.state.deletedAt) +
                          " "
                        : " Ultima modifica il " +
                          this.formatDate(this.state.updatedAt)
                      : this.formatDate(this.state.createdAt)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
          <MyDialog
            isOpen={this.state.isOpen}
            handleClose={() => {
              if (this.state.permDeleted) this.updateList();

              this.setState({ isOpen: false });
            }}
            title={"Esito operazione"}
            maxWidth="xs"
          >
            {this.state.msg}
          </MyDialog>
        </Card>
      </Grid>
    );
  }
}
export default compose(withStyles(styles), withFirebase)(User);
