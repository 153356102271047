import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500,
  },
});
export default function NewLineText(props) {
  const text = props.text;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {text.split("\n").map((str) => (
        <Typography variant="subtitle2" gutterBottom>
          {str}
        </Typography>
      ))}
    </div>
  );
}
