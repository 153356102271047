import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import { green, red, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: { flexGrow: 1 },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  chip: {
    margin: 3,
  },
});
const formatDate = (d) => {
  let data = moment(d).format("DD/MM/YYYY");
  let ora = moment(d).format("HH:mm:ss");
  return data + " alle ore " + ora;
};
const status = (status) => {
  let s, c;
  switch (status) {
    case 0:
      s = "cloud_upload";
      c = { color: yellow[500], align: "center", fontSize: 25 };
      break;
    case -1:
      s = "cloud_off";
      c = { color: red[500], align: "center", fontSize: 25 };
      break;

    default:
      s = "cloud_done";
      c = { color: green[500], align: "center", fontSize: 25 };
      break;
  }
  return <Icon style={c}>{s}</Icon>;
};

function NewsTile(props) {
  const { news } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        action={
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {" Ultima Modifica il " + formatDate(news.updated_at)}
          </Typography>
        }
        title={<div style={{ marginRight: 3 }}>{news.title}</div>}
        subheader={
          <Link rel="noopener noreferrer" target="_blank" href={news.link}>
            {news.link}
          </Link>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="body2" component="p">
              {news.body}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="overline" display="block">
              |&nbsp;&nbsp;Categorie:{" "}
              {news.tags.map((t) => (
                <Chip
                  label={t.title}
                  className={classes.chip}
                  variant="outlined"
                  color="primary"
                  size="small"
                  key={t.id}
                />
              ))}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="overline"
              display="block"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              |&nbsp;&nbsp;Stato:&nbsp;&nbsp;{status(news.status)}
              &nbsp;&nbsp;|&nbsp;&nbsp;Data invio notifica:&nbsp;&nbsp;
              {formatDate(news.dateToBePublished)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Typography
          variant="button"
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginLeft: 10,
          }}
        >
          |&nbsp;&nbsp;Autore:&nbsp;&nbsp;<em>{news.author.userName}</em>
        </Typography>
      </CardActions>
    </Card>
  );
}
export default NewsTile;
