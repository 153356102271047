import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withAuthorization } from "../../session";
import { withFirebase } from "../../services";
import { compose } from "recompose";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));
const Problems = () => (
  <div>
    <h1>Problemi</h1>
    <p>Ci scrivo i probbbbbblemi</p>
  </div>
);
const condition = (authUser) => !!authUser;
export default compose(withAuthorization(condition), withFirebase)(Problems);
