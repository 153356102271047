import Cookies from "js-cookie";
const cookieName = "newsApp";
const HandleCookie = {
  getCookie: async () => {
    const cookie = Cookies.get(cookieName);
    return JSON.parse(cookie);
  },
  setCookie: async (usr) => {
    Cookies.set(cookieName, usr, { expires: 7 });
    return true;
  },
  delCookie: () => {
    const del = true;
    Cookies.remove(cookieName);
    return del;
  },
};
export default HandleCookie;
