import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import HandleCookie from "./../../services/cookie";
import LabelIcon from "@material-ui/icons/Label";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Formik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MyDialog } from "./../MyDialog/index";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  myButton: {
    margin: theme.spacing(1),
  },
  root: {
    display: "fluid",
  },
  pos: {
    marginBottom: 12,
  },
  progress: {
    position: "absolute",
  },
  editButton: {
    marginTop: "10px",
    margin: theme.spacing(5),
  },
  formControl: {
    marginLeft: "54px",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  when: {
    paddingRight: "12px",
  },
  separator: {
    color: "#dc3545",
  },
});
class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      icon: "",
      title: "",
      isUpdating: false,
      isOpen: false,
      msg: "",
      deleted: false,
    };
  }
  componentDidMount() {
    this.setState({
      id: this.props.tag.id,
      order: this.props.tag.order,
      name: this.props.tag.name,
      icon: this.props.tag.icon,
      title: this.props.tag.title,
      isOpen: false,
      msg: "",
      deleted: false,
    });
  }
  /*  updateList = () => {
    let NewList = [...this.props.tagList];
    /* for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].id === this.state.id) {
        NewList.splice(i, 1);
      }
    } 

    this.props.callBack(NewList);
  }; */
  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getCredentials = async () => {
    const token = await HandleCookie.getCookie();
    const access = token.access_token;
    const role = token.role;
    const usr = {
      token: access,
      role: role,
    };
    return usr;
  };
  msg = (t) => {
    this.setState({
      msg: t,
    });
  };

  edit = () => {
    this.setState({ isUpdating: true });
  };

  delete = async () => {
    console.log("ora cancello" + this.state.id);
    const usr = await this.getCredentials();
    const resp = await this.props.firebase.deleteTag(
      usr.role,
      usr.token,
      this.state.id
    );
    if (resp.isOk) {
      console.log(resp.tag.data.esito);
      this.msg(resp.tag.data.esito);
      this.setState({
        isOpen: true,
        deleted: true,
      });
      this.props.callBack(this.state.id);
    } else {
      console.log(resp.err.response.data.esito);
      this.msg(resp.err.response.data.esito);
      this.setState({
        isOpen: true,
      });
    }
  };

  update = async (v) => {
    console.log("ora salvo" + this.state.id);
    const usr = await this.getCredentials();
    //console.log(usr);
    console.log(v);
    const resp = await this.props.firebase.editTag(usr.role, v, usr.token);
    if (resp.isOk) {
      const updatedTag = resp.tag.data.tag;
      console.log(resp.tag.data.tag);
      this.msg(resp.tag.data.esito);

      this.setState({
        id: updatedTag.id,
        name: updatedTag.name,
        order: updatedTag.order,
        icon: updatedTag.icon,
        title: updatedTag.title,
        isOpen: true,
      });
    } else {
      console.log(resp.err.response.data.what);
      this.msg(resp.err.response.data.what);
      this.setState({
        isOpen: true,
      });
    }
    this.setState({ isUpdating: false });
  };

  reset = (p) => {
    console.log(p);
    this.setState({ isUpdating: false });
    //this.formik.current.resetForm();
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" color="primary">
              <LabelIcon />
              {this.capitalize(this.state.title)}{" "}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={{
                id: this.state.id,
                order: this.state.order,
                name: this.state.name,
                title: this.state.title,
                icon: this.state.icon,
              }}
              onSubmit={async (values, actions) => {
                //console.log(values);
                this.update(values);
              }}
              onReset={(values, actions) => {
                this.setState({ isUpdating: false });
              }}
            >
              {(props, isSubmitting) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item md={1} xs={12}>
                      <TextField
                        fullWidth
                        label="Ordine"
                        margin="dense"
                        name="order"
                        variant="outlined"
                        type="number"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.order}
                        disabled={!this.state.isUpdating}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Nome"
                        margin="dense"
                        name="name"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.name}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Titolo"
                        margin="dense"
                        name="title"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.title}
                      />
                    </Grid>

                    <Grid item md={2} xs={6}>
                      <TextField
                        fullWidth
                        label="Icona"
                        margin="dense"
                        name="icon"
                        variant="outlined"
                        disabled={!this.state.isUpdating}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.icon.split(".").pop()}
                      />
                    </Grid>
                    <Grid item md={1} xs={6}>
                      <Icon
                        style={{ color: "red", fontSize: 30, marginTop: 10 }}
                      >
                        {props.values.icon.split(".").pop()}
                      </Icon>
                    </Grid>
                    {this.props.role !== "editor" ? (
                      <Grid item md={2} xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          className={classes.editButton}
                          startIcon={<SaveIcon />}
                          disabled={!this.state.isUpdating}
                        >
                          Aggiorna
                          {isSubmitting && (
                            <CircularProgress
                              size={30}
                              className={classes.progress}
                            />
                          )}
                        </Button>
                        {this.state.isUpdating ? (
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.editButton}
                            startIcon={<UndoIcon />}
                            disabled={!this.state.isUpdating}
                            onClick={props.handleReset}
                          >
                            Annulla&nbsp;&nbsp;&nbsp;
                          </Button>
                        ) : null}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
          {this.props.role !== "editor" ? (
            <CardActions>
              <Grid container spacing={3}>
                <Grid item md={2} xs={6}>
                  {this.state.deletedAt ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.myButton}
                      startIcon={<UndoIcon />}
                      disabled={this.state.isUpdating}
                      onClick={() => {
                        this.undo();
                      }}
                    >
                      Annulla
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.myButton}
                      startIcon={<EditIcon />}
                      disabled={this.state.isUpdating}
                      onClick={() => {
                        this.edit();
                      }}
                    >
                      Modifica
                    </Button>
                  )}
                </Grid>
                <Grid item md={2} xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.myButton}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      this.delete();
                    }}
                  >
                    Elimina
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          ) : (
            ""
          )}
          <MyDialog
            isOpen={this.state.isOpen}
            handleClose={() => {
              this.setState({ isOpen: false });
              if (this.state.deleted) {
                this.props.callBack(this.state.id);
              }
            }}
            title={"Esito operazione"}
            maxWidth="xs"
          >
            {this.state.msg}
          </MyDialog>
        </Card>
      </Grid>
    );
  }
}
export default compose(withStyles(styles), withFirebase)(Tag);
