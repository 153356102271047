//external routes
export const SIGNUP = "/register";
export const LOGIN = "/login";
//users management routes
export const USERS = "/allusers";
export const EDIT_USER = "/edit-user";
export const DELETE = "/users/";
export const UNDO_DELETE = "/users/restore/";
export const PERM_DELETE = "/users/permdelete/";
//tags management routes
export const SHOW_TAGS = "/alltags";
export const EDIT_TAG = "/edit-tag";
export const DELETE_TAG = "/tags/";
export const ADD_TAG = "/new-tag";
//drafts management routes
export const SHOW_DRAFTS = "/alldrafts";
export const ADD_DRAFT = "/new-draft";
export const EDIT_DRAFT = "/edit-draft";
export const DELETE_DRAFT = "/drafts/";
//news management routes
export const SHOW_NEWS = "/allnews";
export const LAST_NEWS = "/last-news";
export const ADD_NEWS = "/new-news";
export const EDIT_NEWS = "/edit-news";
export const DELETE_NEWS = "/news/";
//stats management routes
export const DISTRIBUITION = "/stats-distribuition";
export const NEWS_COUNT = "/stats-news-count";
export const STATS_APP = "/stats-app";
