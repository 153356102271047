import "./styles.css";
import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";

import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import { compose } from "recompose";
import { Grid } from "@material-ui/core";
import COLORS from "./../../const/colors";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
function StatistichePie(props) {
  const [distrib, setDistrib] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const getDistribuition = async () => {
      const resp = await props.firebase.getDistribuition(
        props.role,
        props.token
      );
      if (resp.isOk) {
        const d = resp.distribuition.data.distribuition;
        console.log(d);
        setDistrib([...d]);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
      }
    };

    getDistribuition();
  }, [props.firebase, props.token, props.role, error]);
  return (
    <Grid container>
      <Grid item md={12}>
        <Title>Distribuzione delle notizie per categoria</Title>
        <CssBaseline />
      </Grid>
      <Grid item md={12}>
        <PieChart width={500} height={400}>
          <Pie
            data={distrib}
            cx={300}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill="#8884d8"
            dataKey="total"
          >
            {distrib.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </Grid>
    </Grid>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(StatistichePie);
