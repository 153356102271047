// Material UI components
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withFirebase } from "../../services/";
import { Formik } from "formik";
import * as Yup from "yup";
import { SIGNUP, PASSWORD_FORGET, HOME } from "./../../const/extRoutes";

import { MyDialog } from "../MyDialog";
import HandleCookie from "./../../services/cookie";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progess: {
    position: "absolute",
  },
});
const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Inserisci una mail valida")
    .required("Campo obbligatorio")
    .trim()
    .matches(
      /^.*(@scuola.alto-adige.it|@provincia.bz.it)$/,
      "Usa la mail istituzionale"
    ),
  password: Yup.string()
    .min(8, "La password deve essere di almeno 8 caratteri")
    .required("Campo obbligatorio")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
      "Almeno 8 caratteri, una lettera maiuscola, una lettera maiuscola, un carattere speciale"
    ),
});
const Login = (props) => {
  const { classes } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState([]);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleDialogClose = () => {
    setStatus({ isOpen: false, err: "" });
    if (status.regOK) {
      setStatus({ regOK: false, isOpen: false });
      //window.location.href = HOME;
      props.history.push(HOME);
    }
  };
  /*  const onLoginSuccess = (uname) => {
    setStatus({
      regOK: "Login effettuato correttamente",
      isOpen: true,
      title: "Ciao " + uname,
    });
  }; */
  const onLoginError = (e) => {
    let err;
    console.log(e.code);
    switch (e.code) {
      case "auth/user-not-found":
        err = "Non abbiamo trovato un account associato a questa mail.";
        break;
      case "auth/wrong-password":
        err = "La password digitata è errata.";
        break;
      default:
        err = e.code + " " + e.message;
        break;
    }
    setStatus({
      err: err,
      isOpen: true,
      title: "Attenzione",
    });
  };
  const onLoginButtonPressed = async (values, setSubmitting) => {
    const { email, password } = values;
    try {
      const response = await props.firebase.loginWithEmail(email, password);
      /* if (response.user) {
        setSubmitting(false);
        props.history.push(HOME);
      } */
      if (response.user) {
        const uid = await props.firebase.getIdToken();
        if (uid) {
          const user = await props.firebase.findUser(uid);
          setSubmitting(false);
          if (user.isAuthError) {
            onLoginError(user.err);
          } else {
            const tokenset = await HandleCookie.setCookie(user.user.data);
            if (tokenset) {
              //onLoginSuccess(user.user.data.userName);
              props.history.push(HOME);
            }
          }
        }
      }
    } catch (error) {
      setSubmitting(false);
      onLoginError(error);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          onLoginButtonPressed(values, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  inputProps={{ style: inputStyle }}
                  id="email"
                  label="Indirizzo Email"
                  name="email"
                  value={values.email}
                  autoComplete="email"
                  autoFocus
                  helperText={touched.email ? errors.email : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                />
                <TextField
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    style: inputStyle,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={values.password}
                  autoComplete="current-password"
                  helperText={touched.password ? errors.password : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Login
                  {isSubmitting && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
                {status && (
                  <MyDialog
                    isOpen={status.isOpen ? status.isOpen : false}
                    handleClose={handleDialogClose}
                    title={status.title ? status.title : ""}
                    maxWidth="xs"
                  >
                    <Typography component={"span"} variant={"body2"}>
                      {status.regOK ? status.regOK : status.err}
                    </Typography>
                  </MyDialog>
                )}
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <Link href={SIGNUP} variant="body2">
                      {"Registrati"}
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Link href={PASSWORD_FORGET} variant="body2">
                      {"Password dimenticata?"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        )}
      </Formik>
    </div>
  );
};
export default compose(withRouter, withFirebase, withStyles(styles))(Login);
