import React from "react";

const FirebaseContext = React.createContext(null);
export const FirebaseProvider = FirebaseContext.Provider;
export const FirebaseConsumer = FirebaseContext.Consumer;
/* const withFirebase = (Component) => (props) => (
  <FirebaseConsumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseConsumer>
); */
const withFirebase = (Component) => (props) => (
  <FirebaseConsumer>
    {(state) => <Component {...props} firebase={state} />}
  </FirebaseConsumer>
);
export { withFirebase };
