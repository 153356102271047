import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import Typography from "@material-ui/core/Typography";
import Tag from "../Tags";
import AddTag from "../AddTag";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
function ManageTags(props) {
  const classes = useStyles();

  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const msg = "Non si è autorizzati ad accedere alla risorsa. ";

  useEffect(() => {
    const getTags = async () => {
      const resp = await props.firebase.getTags(props.role, props.token);
      if (resp.isOk) {
        const allTags = resp.tags.data.tags;
        console.log(allTags);
        setTags([...allTags]);
        setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getTags();
  }, [props.firebase, props.token, props.role, error]);
  const addnewTag = (newItem) => {
    setTags([...tags, newItem]);
  };
  const updateTagList = (id) => {
    let NewList = [...tags];
    for (let i = NewList.length - 1; i >= 0; --i) {
      if (NewList[i].id === id) {
        NewList.splice(i, 1);
      }
    }

    setTags(NewList);
  };
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            {props.role === "editor" ? (
              ""
            ) : (
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                  <Title>Aggiungi Categoria</Title>
                  <CssBaseline />
                  <AddTag callBack={addnewTag} />
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Elenco Categorie</Title>

                {isLoading ? (
                  <LinearProgress />
                ) : (
                  <Grid container spacing={2}>
                    {tags.map((tag) => (
                      <Tag
                        tag={tag}
                        key={tag.id}
                        role={props.role}
                        //tagList={tags}
                        // callBack={setTags}
                        callBack={updateTagList}
                      ></Tag>
                    ))}
                  </Grid>
                )}
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                }}
                title={"Esito operazione"}
                maxWidth="xs"
              >
                <Typography component={"span"} variant={"body2"}>
                  {msg}
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}
const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(ManageTags);
