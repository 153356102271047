import React, { useState, useEffect } from "react";

import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
export default function MyBadge() {
  const [notice, setNotice] = useState(0);

  useEffect(() => {
    setNotice(getRandomInt(100));
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Badge badgeContent={notice} color="secondary">
        <NotificationsIcon />
      </Badge>
    </div>
  );
}
