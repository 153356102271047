import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import Typography from "@material-ui/core/Typography";
import StatistichePie from "../StatistichePie";
import StatisticheBar from "../StatisticheBar";
import TagList from "../TagList";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
}));
function ManageStatistiche(props) {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [stats, setStats] = useState(0);
  const msg = "Non si è autorizzati ad accedere alla risorsa. ";

  useEffect(() => {
    const getAppStats = async () => {
      const resp = await props.firebase.getAppStats(props.role, props.token);
      if (resp.isOk) {
        const s = resp.stats.data;
        console.log(s);
        setStats(s);
      } else {
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getAppStats();
  }, [props.firebase, props.token, props.role, error]);
  const formatDate = () => {
    let data = moment().format("DD/MM/YYYY");
    let ora = moment().format("HH:mm:ss");
    return data + " alle ore " + ora;
  };
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper}>
                <Title>Statistiche Riepilogative</Title>
                <CssBaseline />
                {stats ? (
                  <div>
                    <Typography variant="body1" gutterBottom>
                      Ad oggi sono presenti{" "}
                      <strong>
                        {stats.newsToSend + stats.news + stats.newsError}
                      </strong>{" "}
                      news di cui <strong>{stats.newsToSend} </strong>sono
                      programmate per l'invio delle notifiche agli utenti.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Al momento<strong>[X]</strong>utenti hanno scaricato
                      l'App.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <em> Ultimo aggiornamento il {formatDate()}</em>
                    </Typography>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </Paper>
            </Grid>

            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <StatistichePie role={props.role} token={props.token} />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <StatisticheBar role={props.role} token={props.token} />{" "}
              </Paper>
            </Grid>

            <Grid item md={12} xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <TagList
                    title="Legenda"
                    role={props.role}
                    token={props.token}
                  />
                </Grid>
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                }}
                title={"Esito operazione"}
                maxWidth="xs"
              >
                <Typography component={"span"} variant={"body2"}>
                  {msg}
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(ManageStatistiche);
