import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Navigation from "../Navigation";
import { EXT_ROUTES } from "../../const/extRoutes";
import { withAuthentication } from "./../../session/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#930b1c",
      main: "#dc3545",
      dark: "#d50000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e2f1f8",
      main: "#9ea7aa",
      dark: "#cfd8dc",
      contrastText: "#fff",
    },
  },
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <Router>
          <Navigation />
          <Switch>
            {EXT_ROUTES.map((route) => (
              <Route exact path={route.path} key={route.path}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default withAuthentication(App);
