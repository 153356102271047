import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import TagList from "../TagList";
import Skeleton from "@material-ui/lab/Skeleton";
import NewsTile from "../NewsTile";
import Icon from "@material-ui/core/Icon";
import { green, red, yellow } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
function Home(props) {
  const classes = useStyles();
  const [stats, setStats] = useState(null);
  const [error, setError] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [news, setNews] = useState([]);
  const msg = "Non si è autorizzati ad accedere alla risorsa. ";
  const LEGEND = [
    {
      s: "cloud_upload",
      c: { color: yellow[500], align: "center", fontSize: 25 },
      t: "Notifica da inviare.",
      i: 0,
    },
    {
      s: "cloud_off",
      c: { color: red[500], align: "center", fontSize: 25 },
      t: "Problema con la notifica.",
      i: -1,
    },
    {
      s: "cloud_done",
      c: { color: green[500], align: "center", fontSize: 25 },
      t: "Notifica inviata.",
      i: 1,
    },
  ];
  useEffect(() => {
    const getAppStats = async () => {
      const resp = await props.firebase.getAppStats(props.role, props.token);
      if (resp.isOk) {
        const s = resp.stats.data;
        setStats(s);
      } else {
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getAppStats();
  }, [props.firebase, props.token, props.role, error]);
  useEffect(() => {
    const getLastNews = async () => {
      const resp = await props.firebase.getLastNews(props.role, props.token);
      if (resp.isOk) {
        const n = resp.news.data.news;
        //console.log(n);
        setNews([...n]);
      } else {
        //console.log(resp.err.response.data.error);
        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };

    getLastNews();
  }, [props.firebase, props.token, props.role, error]);
  const formatDate = () => {
    let data = moment().format("DD/MM/YYYY");
    let ora = moment().format("HH:mm:ss");
    return data + " alle ore " + ora;
  };
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper className={classes.paper}>
                <Title>Ultime news in piattaforma</Title>
                <CssBaseline />

                <div>
                  <Typography variant="body1" gutterBottom>
                    <em> Ultimo aggiornamento il {formatDate()}</em>
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <em>Legenda:&nbsp;</em>
                  </Typography>
                  <Grid container spacing={1}>
                    {LEGEND.map((l) => (
                      <Grid item md={4} key={l.i}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {" "}
                          <Icon style={l.c}>{l.s}</Icon>
                          &nbsp;&nbsp;{l.t}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Divider />
                  <br />
                  {news ? (
                    <Grid container spacing={1}>
                      {news.map((n) => (
                        <Grid item md={12}>
                          <NewsTile news={n} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <LinearProgress />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} xs={12}>
              {stats ? (
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Paper className={classes.paper}>
                      <Title>Bozze salvate</Title>
                      <CssBaseline />
                      {stats.draftCount === 0 ? (
                        <Typography variant="body1" gutterBottom>
                          <em>Non ci sono bozze salvate in piattaforma.</em>
                        </Typography>
                      ) : stats.draftCount === 1 ? (
                        <Typography variant="body1" gutterBottom>
                          E' presente in piattaforma
                          <strong> {stats.draftCount}</strong> bozza salvata.
                        </Typography>
                      ) : (
                        <Typography variant="body1" gutterBottom>
                          Sono presenti in piattaforma
                          <strong> {stats.draftCount}</strong> bozze salvate.
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item md={12}>
                    <Paper className={classes.paper}>
                      <Title>Invio notifiche</Title>
                      <CssBaseline />
                      {stats.newsToSend === 0 ? (
                        <Typography variant="body1" gutterBottom>
                          <em>Non ci sono notifiche da inviare.</em>
                        </Typography>
                      ) : stats.newsToSend === 1 ? (
                        <Typography variant="body1" gutterBottom>
                          C'è
                          <strong> {stats.newsToSend}</strong> notifica
                          programmata per l'invio.
                        </Typography>
                      ) : (
                        <Typography variant="body1" gutterBottom>
                          Ci sono
                          <strong> {stats.newsToSend}</strong> notifiche
                          programmate per l'invio.
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item md={12}>
                    <Paper className={classes.paper}>
                      <Title>Problemi notifiche</Title>
                      <CssBaseline />
                      {stats.newsError === 0 ? (
                        <Typography variant="body1" gutterBottom>
                          <em>Non si sono verificati errori.</em>
                        </Typography>
                      ) : stats.newsError === 1 ? (
                        <Typography variant="body1" gutterBottom>
                          Si è verificato
                          <strong> {stats.newsError}</strong> problema durante
                          l'invio delle notifiche.
                        </Typography>
                      ) : (
                        <Typography variant="body1" gutterBottom>
                          Si sono verificati
                          <strong> {stats.newsError}</strong> problemi durante
                          l'invio delle notifiche.
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={12}>
                  <Paper className={classes.paper}>
                    {" "}
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width={350}
                      height={118}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
            <Grid item md={12}>
              <Paper className={classes.paper}>
                <TagList
                  title="Categorie"
                  role={props.role}
                  token={props.token}
                />
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                }}
                title={"Esito operazione"}
                maxWidth="xs"
              >
                <Typography component={"span"} variant={"body2"}>
                  {msg}
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(Home);
