import LoginPage from "../components/Login";
import SignUpPage from "../components/SignUp";
import ResetPswPage from "../components/PswReset";
import Home from "./../components/Home";
export const EXT_ROUTES = [
  { path: "/", navName: "", name: "LANDING", component: LoginPage },
  {
    path: "/registrati",
    navName: "Registra il tuo account",
    name: "SIGN_UP",
    component: SignUpPage,
  },
  {
    path: "/login",
    navName: "Accedi alla piattaforma",
    name: "SIGN_IN",
    component: LoginPage,
  },
  {
    path: "/password-dimenticata",
    navName: "Recupera la tua password",
    name: "RECOVERY_PWD",
    component: ResetPswPage,
  },
];
export const NAV_ROUTES = [
  {
    role: ["god", "editor"],
    path: "/home",
    icon: "home",
    navName: "Home",
    sidebarName: "Home",
    component: Home,
  },
];
export const LANDING = "/";
export const SIGNUP = "/registrati";
export const LOGIN = "/login";
export const PASSWORD_FORGET = "/password-dimenticata";
export const HOME = "/home";
