import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/logo.png";
import Button from "@material-ui/core/Button";
import { EXT_ROUTES, LANDING, SIGNUP } from "../../const/extRoutes";
import IconButton from "@material-ui/core/IconButton";
import { LOGIN } from "./../../const/extRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 40,
  },
}));

function NavigationNotAuth(props) {
  const classes = useStyles();
  const [where, setWhere] = useState("");
  const history = useHistory();
  const pathname = history.location.pathname;
  const getPath = (pathname) => {
    //let w = pathname.split("/")[1];
    //setWhere(w.charAt(0).toUpperCase() + w.slice(1));
    EXT_ROUTES.forEach((route) => {
      if (pathname === route.path) setWhere(route.navName);
    });
  };
  useEffect(() => {
    getPath(pathname);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => history.push(LANDING)}>
            <img src={logo} alt="logo" className={classes.logo} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            NewsApp &nbsp;&nbsp; {where}
          </Typography>
          <Button
            color="inherit"
            onClick={() => history.push(LOGIN, { from: "home" })}
          >
            Login
          </Button>
          <Button
            color="inherit"
            onClick={() => history.push(SIGNUP, { from: "home" })}
          >
            Registrati
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default withRouter(NavigationNotAuth);
