import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Title from "../Title";
import AuthUserContext from "./../../session/context";
import { withAuthorization } from "./../../session/";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import User from "../Users";
import { Card, CardContent, Divider, Grid, TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MyDialog } from "./../MyDialog/index";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
function ManageUsers(props) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const msg = "Non si è autorizzati ad accedere alla risorsa. ";

  useEffect(() => {
    const getUser = async () => {
      const resp = await props.firebase.getUsers(props.role, props.token);

      if (resp.isOk) {
        const allUsers = resp.users.data.users;
        //console.log(allUsers);
        setUsers([...allUsers]);
        setIsLoading(false);
      } else {
        //console.log(resp.err.response.data.error);

        setError(resp.err.response.data.error);
        if (error !== "") setOpen(true);
      }
    };
    getUser();
  }, [props.firebase, props.role, props.token, error]);

  const [filter, setFilter] = useState("");
  const handleChange = (event) => {
    setFilter(event.target.value);
    console.log(event.target.value);

    /* const filteredUsers =
      lowercasedFilter !== ""
        ? users.filter((item) => {
            return Object.keys(item).some(
              () =>
                item["firstName"].toLowerCase().includes(lowercasedFilter) ||
                item["lastName"].toLowerCase().includes(lowercasedFilter) ||
                item["userName"].toLowerCase().includes(lowercasedFilter)
            );
          })
        : users; */
    console.log(filteredUsers);
  };
  const lowercasedFilter = filter.toLowerCase();
  /*  const filteredData = users.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(lowercasedFilter)
    );
  }); */
  const filteredUsers =
    lowercasedFilter !== ""
      ? users.filter((item) => {
          return Object.keys(item).some(
            () =>
              item["firstName"].toLowerCase().includes(lowercasedFilter) ||
              item["lastName"].toLowerCase().includes(lowercasedFilter) ||
              item["userName"].toLowerCase().includes(lowercasedFilter)
          );
        })
      : users;
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper}>
                <Title>Gestione utenti</Title>
                <CssBaseline />
                <Card>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Filtra risultati"
                          margin="dense"
                          name="filter"
                          variant="outlined"
                          value={filter}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {isLoading ? (
                  <LinearProgress />
                ) : (
                  <Grid container spacing={2}>
                    {filteredUsers.map((user) => (
                      <User
                        user={user}
                        key={user.userId}
                        userList={users}
                        callBack={setUsers}
                      ></User>
                    ))}
                  </Grid>
                )}
              </Paper>
              <MyDialog
                isOpen={isOpen}
                handleClose={() => {
                  setOpen(false);
                }}
                title={"Esito operazione"}
                maxWidth="xs"
              >
                <Typography component={"span"} variant={"body2"}>
                  {msg}
                  <br />
                  {error}
                </Typography>
              </MyDialog>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}
const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(ManageUsers);
