import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import logo from "../../assets/logo.png";
import { withRouter, useHistory } from "react-router-dom";
import { withFirebase } from "../../services";
import { navRoutes } from "./../../const/navRoutes";
import { LANDING } from "../../const/extRoutes";
import HandleCookie from "./../../services/cookie";
import MyBadge from "./../MyBadge/";
import Container from "@material-ui/core/Container";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MyAvatar from "./../MyAvatar/index";
import SideBar from "../SideBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Home from "../Home";
import ManageUsers from "../ManageUsers";
import MyProfile from "../MyProfile";
import ManageTags from "../ManageTags";
import ManageNews from "../ManageNews";
import ManageStatistiche from "../ManageStatistiche";
import NewsList from "../NewsList";
import Problems from "../Problems";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    backgroundColor: "#dc3545",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 18,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    maxWidth: 40,
  },
}));

function NavigationAuth(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [where, setWhere] = useState("");
  const history = useHistory();
  const pathname = history.location.pathname;
  const [open, setOpen] = useState(false);
  const sep = " \\ ";
  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState("");
  const [userToken, setUserToken] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");

  const welcome = (f, l) => {
    let hello;
    hello = f.charAt(0).toUpperCase() + l.charAt(0).toUpperCase();
    setWelcomeMessage(hello);
  };
  const getUser = async () => {
    /* const token = await HandleCookie.getCookie();
    if (token) {
      const currUser = JSON.parse(token);
      setUser(currUser);
      console.log(user);
    } */
    const uid = await props.firebase.getIdToken();
    if (uid) {
      const currUser = await props.firebase.findUser(uid);
      if (currUser.isAuthError) {
        //console.log(currUser.err);
      } else {
        setUserRole(currUser.user.data.role);
        setUser(currUser.user.data);
        welcome(currUser.user.data.firstName, currUser.user.data.lastName);
        setUserToken(currUser.user.data.access_token);
      }
    }
  };
  useEffect(() => {
    getUser();
    if (window.screen.width >= 1280) {
      setOpen(true);
    }

    //console.log(props.authUser);
    // const currUser = JSON.parse(token.toString());
    // setUser(currUser);
    // setUserRole(currUser.role);
    // console.log(currUser.role);
    // welcome(currUser.firstName, currUser.lastName);
  }, []);
  /*  const getUserRole = async () => {
    if (user) {
      setUserRole(user.role);
    }
  }; */
  /*  useEffect(() => {
    if (user) {
      setUserRole(user.role);
    }
  }, []);
  const getUser = () => {
    const userToken = HandleCookie.getCookie();
    if (userToken) {
      const currUser = JSON.parse(userToken);
      return currUser;
    } else return false;
  };
  useEffect(() => {
    const currUser = getUser();
    if (currUser) {
      setUser(currUser);
    }
    console.log(user);
  }, []);

  const welcome = (user) => {
    let hello;
    hello =
      user.firstName.charAt(0).toUpperCase() +
      user.lastName.charAt(0).toUpperCase();
    setWelcomeMessage(hello);
  };
  useEffect(() => {
    if (user) welcome(user);
  }, []); */

  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getPath = (pathname) => {
    navRoutes.forEach((route) => {
      if (pathname === route.path) {
        setWhere(route.sidebarName);
      }
    });
  };
  const onExit = async () => {
    await props.firebase.signOut();
    const exit = await HandleCookie.delCookie();
    if (exit) window.location.replace(LANDING);
    //window.location.replace(LANDING);
  };
  const onProblems = () => {
    history.push("/problemi");
  };

  useEffect(() => {
    getPath(pathname);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <IconButton>
            <img src={logo} alt="logo" className={classes.logo} />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            NewsApp {where ? sep + where : ""}
          </Typography>
          <IconButton color="inherit" onClick={() => onProblems()}>
            <MyBadge></MyBadge>
          </IconButton>
          <MyAvatar userName={welcomeMessage} />
          <IconButton color="inherit" onClick={() => onExit()}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </div>

        <Divider />
        {userRole ? <SideBar role={userRole}></SideBar> : <LinearProgress />}
      </Drawer>
      {user ? (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {
              {
                Home: <Home role={userRole} token={userToken} />,
                Utenti: <ManageUsers role={userRole} token={userToken} />,
                "Modifica profilo": <MyProfile currUser={user} />,
                Categorie: <ManageTags role={userRole} token={userToken} />,
                "Aggiungi News": (
                  <ManageNews token={userToken} role={userRole} />
                ),
                News: <NewsList role={userRole} token={userToken} />,
                Statistiche: (
                  <ManageStatistiche role={userRole} token={userToken} />
                ),
                Problemi: <Problems role={userRole} token={userToken} />,
              }[where]
            }
          </Container>
        </main>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}
export default withFirebase(withRouter(NavigationAuth));
