import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../services/";
import { compose } from "recompose";
import CssBaseline from "@material-ui/core/CssBaseline";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Grid } from "@material-ui/core";
import Title from "../Title";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import COLORS from "./../../const/colors";
import LinearProgress from "@material-ui/core/LinearProgress";
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    verticalAlign: "middle",
  },
}));

function TagList(props) {
  const { title } = props;
  const [tags, setTags] = useState(null);
  const [error, setError] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    const getTags = async () => {
      const resp = await props.firebase.getTags(props.role, props.token);
      if (resp.isOk) {
        const allTags = resp.tags.data.tags;
        setTags([...allTags]);
      } else {
        setError(resp.err.response.data.error);
      }
    };

    getTags();
  }, [props.firebase, props.token, props.role, error]);

  return (
    <Grid item md={12}>
      <Title>{title}</Title>
      <CssBaseline />

      {error === "" ? (
        <Typography variant="body1" gutterBottom>
          Si sono verificati i seguenti errori: {error}
        </Typography>
      ) : tags ? (
        <List className={classes.flexContainer}>
          {tags.map((t, i) => (
            <ListItem key={t.id}>
              <ListItemAvatar>
                <Avatar variant="square" style={{ backgroundColor: COLORS[i] }}>
                  <Icon
                    style={{
                      color: "white",
                      fontSize: 30,
                    }}
                  >
                    {t.icon.split(".").pop()}
                  </Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t.title} />
            </ListItem>
          ))}
        </List>
      ) : (
        <LinearProgress />
      )}
    </Grid>
  );
}
export default compose(withFirebase)(TagList);
